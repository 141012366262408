<template>
    <div id="fiche-client-page" class="pt-4 mb-6">
        <div class="bg-info text-center text-white" id="ariane">
            <b-button @click="getBack">
                <i class="fa fa-chevron-left"></i> retour
            </b-button>
            Clients
        </div>
        <div class="row form-group mt-5">
            <!--Map-->
            <div class="col-12 col-lg-12 order-lg-0 form-group">
                <div class="remove-margin-on-sm  h-100">
                    <Gmap ref="map" height="200px"></Gmap>
                </div>
            </div>
            <!--Récap adresse-->
            <div class="col-12 col-lg-12 order-lg-1 form-group" v-if="client">
                <div class="card form-group">
                    <div class="card-header bg-primary">
                        Adresse
                    </div>
                    <div class="card-body">
                        <h5 class="card-title font-weight-bold nameBlock"></h5>
                        <div class="row align-items-center">
                            <div class="col adressBlock" v-if="client">
                                <div><strong>{{ client.customer_title }}. {{ client.customer_firstname }}
                                    {{ client.customer_lastname }}</strong></div>
                                <div>{{ client.adress1 }}</div>
                                <div v-if="client.adress2">{{ client.adress2 }}</div>
                                <div>{{ client.postal_code }} {{ client.city }}</div>
                            </div>
                            <div class="col">
                                <div class="row justify-content-end no-gutters">
                                    <div class="col-auto mr-1">
                                        <a class="btn callTheContactBtn btn-success"
                                           :href="'tel:' + client.contact_value">
                                            <i class="fas fa-phone"></i>
                                        </a>
                                    </div>
                                    <div class="col-auto mr-1">
                                        <a class="btn showTheMapBtn btn-danger"
                                           @click="getMap(client.latitude, client.longitude)">
                                            <i class="fas fa-map-marked-alt"></i>
                                        </a>
                                    </div>
                                    <div class="col-auto">
                                        <a class="btn showTheMapBtn waze"
                                           :href="'https://www.waze.com/ul?ll=' + client.latitude + '%2C' + client.longitude + '&navigate=yes&zoom=17'"
                                           target="_blank">
                                            <i class="fab fa-waze"></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <h3 class="text-white">Contrats :</h3>

                <div v-if="contracts.length > 0">
                    <b-card no-body class="mb-1" v-for="contract in contracts" :key="contract.idcontract">
                        <b-card-header header-tag="header"
                                       class="p-3 text-left headercontrat d-flex justify-content-between align-items-center">
                            {{ contract.activity_name }}
                            <router-link class="btn btn-outline-dark"
                                         v-if="contract.profiles && contract.profiles.length"
                                         :to="{ name: 'consignes', params: { clientId: $router.currentRoute.params.clientId, profiles: contract.profiles } }">
                                Voir les consignes
                            </router-link>
                        </b-card-header>
                        <b-card-body>
                            <b-row>
                                <span class="text-danger font-weight-bold mr-1">Fréquence :</span> {{
                                    contract.frequence
                                }}
                            </b-row>
                            <b-row v-if="contract.nextIntervention">
                                <span class="text-danger font-weight-bold mr-1">Temps d'intervention :</span>
                                {{
                                    getDureeInter(contract.nextIntervention.start_time, contract.nextIntervention.end_time)
                                }}
                                h
                            </b-row>
                            <b-row v-if="contract.nextIntervention">
                                <span class="text-danger font-weight-bold mr-1">Prochaine intervention :</span> {{
                                    getFormattedDate(contract.nextIntervention.date_slot, contract.nextIntervention.start_time)
                                }}
                            </b-row>
                            <b-row v-if="contract.contract_recipients.length">
                                <span class="text-danger font-weight-bold mr-1 w-100">Bénéficiaires :</span>
                                <b-list-group class="w-100">
                                    <Recipient v-for="recipient in contract.contract_recipients"
                                               beneficiaire
                                               :key="recipient.idrecipient"
                                               :recipient="recipient"
                                    ></Recipient>
                                </b-list-group>
                            </b-row>
                            <b-row v-if="contract.customer_recipients.length">
                                <span class="text-danger font-weight-bold mr-1 w-100">Interlocuteurs :</span>
                                <b-list-group class="w-100">
                                    <Recipient v-for="recipient in contract.customer_recipients"
                                               :key="recipient.idrecipient"
                                               :recipient="recipient"
                                    ></Recipient>
                                </b-list-group>
                            </b-row>
                        </b-card-body>
                    </b-card>
                </div>
                <!--bouton voir fiche mission-->
                <!--<div class="list-group text-dark">
                    <router-link :to="{ name: 'mission', params: { clientId: client.id } }" class="list-group-item list-group-item-action">
                        <div class="centered-ico col-2 bg-primary"><i class="fas fa-eye fa-2x"></i></div>
                        <div class="align-items-center row text-dark">
                            <div class="col-8 text-center offset-2">
                                <span class="d-block text-truncate">Voir la fiche de mission</span>
                            </div>
                            <div class="col-2 text-right">
                                <i class="fas fa-chevron-right fa-2x"></i>
                            </div>
                        </div>
                    </router-link>
                </div>-->
            </div>
        </div>
        <!--<div>
            <a href="#detection" class="d-block">
                <div class="card bg-success text-white">
                    <div class="card-body">
                        <h3 class="d-flex align-items-center justify-content-center m-0">
                            <span class="align-items-center d-flex"><i class="far fa-clock fa-2x  far mr-3"></i> Déclarer une prestation</span>
                        </h3>
                    </div>
                </div>
            </a>
        </div>-->
    </div>
</template>

<script>
import router from '../../router'
import clientService from '../../services/clientService'
import Gmap from '../Gmap'
import planningService from "../../services/planningService";
import Recipient from "./Client/Recipient";

export default {
    name: "FicheClient",
    components: {Gmap, Recipient},
    data() {
        return {
            client: null,
            contracts: []
        }
    },
    mounted() {
        this.$emit('menu', 'clients')
        this.getClient()
    },
    methods: {
        getBack() {
            router.go(-1)
        },
        msToTime(duration) {
            let minutes = Math.floor((duration / (1000 * 60)) % 60)
            let hours = Math.floor((duration / (1000 * 60 * 60)) % 24)

            hours = (hours < 10) ? "0" + hours : hours
            minutes = (minutes < 10) ? "0" + minutes : minutes

            return hours + ":" + minutes
        },
        getDureeInter(date_debut, date_fin) {
            date_debut = new Date(this.$moment().format('YYYY-MM-DD') + ' ' + date_debut)
            date_fin = new Date(this.$moment().format('YYYY-MM-DD') + ' ' + date_fin)
            let diffTime = Math.abs(date_debut - date_fin)
            return this.msToTime(diffTime)
        },
        getClient() {
            clientService.getClient(router.currentRoute.params.clientId, router.currentRoute.params.intervAdress).then((response) => {
                if (response.data.etat === 'OK') {
                    this.client = response.data.data
                    if (this.client.customer_firstname !== null) {
                        this.client.customer_firstname = this.client.customer_firstname.charAt(0).toUpperCase() + this.client.customer_firstname.slice(1).toLowerCase()
                    }
                    if (this.$refs.map) {
                        this.$refs.map.makeMarker(parseFloat(this.client.latitude), parseFloat(this.client.longitude), 'Client')
                        this.$refs.map.zoom(parseFloat(this.client.latitude), parseFloat(this.client.longitude))
                    }
                    this.getContracts()
                } else {
                    this.$rollbar.error('Erreur 3038');
                    this.$bvToast.toast('Une erreur est survenue', {
                        title: 'Erreur 3038',
                        autoHideDelay: 5000,
                        variant: 'danger'
                    })
                }
            }).catch((error) => {
                this.$rollbar.error('Erreur 3039', error);
                this.$bvToast.toast('Une erreur est survenue, veuillez réessayer ultérieurement ou contacter le support.', {
                    title: 'Erreur 3039',
                    autoHideDelay: 5000,
                    variant: 'danger'
                })
            })
        },
        getContracts() {
            clientService.getContracts(this.client.customer_idcustomer, this.client.intervention_idadress).then((response) => {
                if (response.data.etat === 'OK') {
                    this.contracts = []
                    response.data.data.forEach((item) => {
                        planningService.getNextInterventionByContract(item.idcontract).then((result) => {
                            if (result.data.etat === 'OK') {
                                item['nextIntervention'] = result.data.data
                                this.contracts.push(item)
                            } else {
                                this.$rollbar.error('Erreur 3040');
                                this.$bvToast.toast('Une erreur est survenue', {
                                    title: 'Erreur 3040',
                                    autoHideDelay: 5000,
                                    variant: 'danger'
                                })
                            }
                        }).catch((error) => {
                            this.$rollbar.error('Erreur 3041', error);
                            this.$bvToast.toast('Une erreur est survenue, veuillez réessayer ultérieurement ou contacter le support.', {
                                title: 'Erreur 3041',
                                autoHideDelay: 5000,
                                variant: 'danger'
                            })
                        })
                    })
                } else {
                    this.$rollbar.error('Erreur 3042');
                    this.$bvToast.toast('Une erreur est survenue', {
                        title: 'Erreur 3042',
                        autoHideDelay: 5000,
                        variant: 'danger'
                    })
                }
            }).catch((error) => {
                this.$rollbar.error('Erreur 3043', error);
                this.$bvToast.toast('Une erreur est survenue, veuillez réessayer ultérieurement ou contacter le support.', {
                    title: 'Erreur 3043',
                    autoHideDelay: 5000,
                    variant: 'danger'
                })
            })
        },
        getFormattedDate(date, hour) {
            return this.$moment(date + ' ' + hour).format('dddd D MMMM HH:mm')
        },
        getMap(lat, lng) {
            if /* if we're on iOS, open in Apple Maps */
            ((navigator.platform.indexOf("iPhone") != -1) ||
                (navigator.platform.indexOf("iPod") != -1) ||
                (navigator.platform.indexOf("iPad") != -1))
                window.open("maps://maps.google.com/maps?daddr=" + lat + "," + lng + "&amp;ll=")
            else /* else use Google */
                window.open("https://maps.google.com/maps?daddr=" + lat + "," + lng + "&amp;ll=")
        }
    }
}
</script>

<style scoped>
#ariane {
    text-align: center;
    padding: 10px;
    position: fixed;
    font-size: 22px;
    top: 45px;
    left: 0;
    right: 0;
    z-index: 2;
}

@media (min-width: 992px) {
    #ariane {
        top: 80px;
    }
}

#ariane button {
    position: absolute;
    left: 15px;
    background: transparent;
    border: none;
}

#ariane button:focus, button.headercontrat:focus {
    box-shadow: none !important;
    color: black;
}

.headercontrat {
    background: #cbf0ff !important;
    color: black;
    border: none;
}

.waze {
    background: #44d4fe;
}
</style>
