<template>
    <b-list-group-item class="mt-1 w-100">
        <b-row class="p-1">
            <b-col cols="2" class="centered-ico bg-info-corporate text-white"><i
                class="fa-2x fas fa-user" aria-hidden="true"></i></b-col>
            <b-col cols="10" class="position-relative" style="left:3.5em" >
                <div>
                    {{ recipient.recipient_lastname }} {{ recipient.recipient_firstname }} -
                    {{ recipient.recipient_status }}
                    <span v-if="beneficiaire && recipient.recipient_birth_day">
                         - {{ $moment().diff($moment(recipient.recipient_birth_day), 'years') }} ans
                    </span>
                </div>
                <div v-if="recipient.contacts.length">
                    <ul>
                        <li v-for="contact in recipient.contacts" :key="contact.id">
                            <a v-if="contact.contact_type_idcontact_type == 1" :href="`mailto:${contact.contact_value}`">{{contact.contact_value}}</a>
                            <a v-else :href="`tel:${contact.contact_value}`">{{contact.contact_value}}</a>
                        </li>
                    </ul>
                </div>
                <div v-if="recipient.adresse">
                    {{ recipient.adresse.adress1 }}, {{ recipient.adresse.postal_code }} {{ recipient.adresse.city }}
                </div>
            </b-col>
        </b-row>
    </b-list-group-item>
</template>

<script>

export default {
    name: "Recipent",
    props: {
        recipient: {
            type: Object,
            required: true
        },
        beneficiaire: {
            type: Boolean,
            default: false
        }
    },
    mounted() {

    },
    data() {
        return {}
    },
    methods: {}
}
</script>

<style scoped>


</style>
