<template>
    <div id="myMap" :style="{ height: height }"></div>
</template>
<script>
    export default{
        name: 'Gmap',
        props: {
            markers: {
                default () {
                    return []
                }
            },
            height: {
                default: '150px'    
            }
        },
        data () {
            return {
                map: null,
                waitingload: false
            }
        },
        mounted () {
            let interval = setInterval(() => {
                if (window.google) {
                    clearInterval(interval)
                    this.init()
                }
            })
        },
        methods: {
            init () {
                this.waitingload = true
                const element = document.getElementById('myMap')
                const options = {
                    zoom: 14,
                    center: new window.google.maps.LatLng(48.864605, 2.343691)
                }
                this.map = new window.google.maps.Map(element, options)
                this.waitingload = false
            },
            makeMarker (lat, lng, title) {
                return new window.google.maps.Marker({
                    position: {
                        lat: lat,
                        lng: lng
                    },
                    map: this.map,
                    draggable: false,
                    icon: null,
                    title: title
                })
            },
            zoom (lat, lng) {
                this.map.setCenter(new window.google.maps.LatLng(lat, lng))
                this.map.setZoom(15)
            }
        }
    }
</script>
<style lang="scss">
</style>
